import Image from 'next/image'
import type { NextPage } from 'next'

import {
    anthonyFromChocoImage,
    chocoLogo,
    gregorFromPersonioImage,
    marblesSlideFasterImage,
    personioLogo,
    philipFromVaiTradeImage,
    stackCardsVideo,
    pipelineTableVideo,
    vaiLogo,
    marblesOnHelixSlideVideo,
    marblesOnSpiralSlideImage,
    tablePipelineImage,
    stackCardsImage,
    marblesSlideFasterVideo,
    quickDemoVideo,
} from 'src/libs/assets'
import { PageLayout } from 'src/libs/layout'
import {
    IntroSection,
    PropositionSection,
    SecuritySection,
    ClosingSection,
    DemoVideoSection,
    Testimonial,
    TeaserCardSliderSection,
} from 'src/libs/ui-shared'
import { LazyVideo } from 'src/libs/react-utils'

import { SignupCTA } from '../ui-shared/request-demo/signup-cta'

import { TrustSection } from './section-trust/trust-section'

export const Homepage: NextPage = () => {
    return (
        <PageLayout isNavBarFixed>
            <div className="grid gap-40">
                <IntroSection
                    image={marblesOnSpiralSlideImage}
                    imageAlt="Marbles sliding down a slide in shape of a spiral"
                    video={marblesOnHelixSlideVideo}
                    title="Make Salesforce easy to use"
                    description="Laserfocus makes your Salesforce process as simple as possible, but not simpler. Made for data-driven GTM teams who don't want to sacrifice efficiency. "
                >
                    <div className="grid justify-items-center md:justify-items-start grid-cols-1">
                        <SignupCTA />
                    </div>
                </IntroSection>

                <DemoVideoSection
                    title="Beautiful, fast, simple to use"
                    description="Organize your tasks, emails, events, notes, and Salesforce updates within one interface. Reduce jumping between different tabs and tools to increase your efficiency and focus."
                    thumbnail={{
                        alt: 'Quick Demo of Laserfocus',
                        image: quickDemoVideo.thumbnail,
                    }}
                    videoUrl={quickDemoVideo.url}
                    backgroundClassName="bg-yellow-50"
                />

                <TrustSection />

                <PropositionSection
                    title="Simple guidelines for each stage"
                    description={[
                        'Laserfocus will guide you on what to focus on at each stage.',
                        'You will never forget any step; nothing slips through the cracks again.',
                    ]}
                    image={
                        <LazyVideo
                            {...stackCardsVideo}
                            placeholderImage={
                                <Image
                                    src={stackCardsImage}
                                    alt="Stack cards"
                                    layout="responsive"
                                />
                            }
                        />
                    }
                    imageShadow
                    swapElementsWhenVertical
                >
                    <Testimonial
                        companyLogo={vaiLogo}
                        companyName="VAI"
                        description="“My team increased productivity by 25% - more calls, more deals.”"
                        authorImage={philipFromVaiTradeImage}
                        authorName="Philip"
                        authorInfo="VP Sales from Vai Trade"
                    />
                </PropositionSection>

                <PropositionSection
                    title="Make your Salesforce clean and usable again"
                    description={[
                        'Eliminate duplicate fields and ambiguous data for consistency.',
                        'Your team will enjoy a streamlined interface while Salesforce runs the workflows behind the scene.',
                    ]}
                    image={
                        <LazyVideo
                            {...marblesSlideFasterVideo}
                            placeholderImage={
                                <Image
                                    src={marblesSlideFasterImage}
                                    alt="Marbles sliding down a fast slide while others take a longer path in the background"
                                    layout="responsive"
                                />
                            }
                        />
                    }
                >
                    <Testimonial
                        companyLogo={personioLogo}
                        companyName="Personio"
                        description="“I am in love. My pipe is up to date for the first time.”"
                        authorImage={gregorFromPersonioImage}
                        authorName="Gregor"
                        authorInfo="Account Executive from Personio"
                    />
                </PropositionSection>

                <PropositionSection
                    title="Stay agile for your GTM"
                    description={[
                        'Your business requirements and processes might change often.',
                        'Laserfocus ensures that everyone adapts to your new process by guiding everyone through the latest changes.',
                    ]}
                    image={
                        <LazyVideo
                            {...pipelineTableVideo}
                            placeholderImage={
                                <Image
                                    src={tablePipelineImage}
                                    alt="Pipeline table"
                                    layout="responsive"
                                    placeholder="blur"
                                />
                            }
                        />
                    }
                    imageShadow
                    swapElementsWhenVertical
                >
                    <Testimonial
                        companyLogo={chocoLogo}
                        companyName="Choco"
                        description="“I started using it and now all the other departments are on Laserfocus, too.”"
                        authorImage={anthonyFromChocoImage}
                        authorName="Anthony"
                        authorInfo="Operations at Choco"
                    />
                </PropositionSection>

                <SecuritySection />

                <TeaserCardSliderSection />

                <ClosingSection
                    title="Streamline your GTM now"
                    description="Enjoy a sales process as simple as possible. You can try it for yourself and enjoy increased efficiency without compromising data quality."
                />
            </div>
        </PageLayout>
    )
}
