import { CONTENT_CONTAINER_STYLES } from 'src/libs/design-system'
import { twMerge } from 'src/libs/tailwind-css'
import { PartnerLogos } from 'src/libs/ui-shared'
import { Appear } from 'src/libs/react-utils'

export function TrustSection() {
    return (
        <Appear>
            <article className={twMerge(CONTENT_CONTAINER_STYLES, 'text-center grid gap-10')}>
                <PartnerLogos />
            </article>
        </Appear>
    )
}
