import { LABEL_STYLES, LinkButton } from 'src/libs/design-system'
import { CreditCardOutlinedIcon } from 'src/libs/icons'
import { twMerge } from 'src/libs/tailwind-css'

export function SignupCTA() {
    return (
        <div className="grid place-content-center">
            <LinkButton
                variant="primary"
                size="large"
                arrowRight
                href="https://app.laserfocus.io/signup"
            >
                Sign up for free
            </LinkButton>
            <div className={twMerge(LABEL_STYLES, 'text-grey-700/50 pt-[0.6rem] text-center')}>
                <CreditCardOutlinedIcon className="w-4 h-4 inline-block mr-2 align-text-bottom" />
                No Credit Card needed.
            </div>
        </div>
    )
}
